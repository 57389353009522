import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/MqHy3tBQP1M"
    bibleGroupSrc="https://www.docdroid.net/20hAJFi/bible-group-homework-10-18-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/45165924"
  >
    <SEO title="Together for Worship - Together is Better" />
  </Layout>
)

export default SermonPost
